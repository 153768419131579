import axios from 'axios';
import { useCurrentUserStore } from '/entrypoints/stores/currentUserStore';

const api = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL,
});

// Interceptor to set the Authorization header before each request
api.interceptors.request.use((config) => {
  const currentUserStore = useCurrentUserStore();

  // Check if token is available and set it in the Authorization header
  if (currentUserStore.user.token) {
    config.headers['Authorization'] = `token ${currentUserStore.user.token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
